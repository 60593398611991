import './Snowflakes.css';

function Snowflakes() {
    return (
      <div className="snowflakes" aria-hidden="true">
        <div className="snowflake">
          <div className="inner">❅</div>
        </div>
        <div className="snowflake">
          <div className="inner">❅</div>
        </div>
        <div className="snowflake">
          <div className="inner">❅</div>
        </div>
        <div className="snowflake">
          <div className="inner">❅</div>
        </div>
        <div className="snowflake">
          <div className="inner">❅</div>
        </div>
        <div className="snowflake">
          <div className="inner">❅</div>
        </div>
        <div className="snowflake">
          <div className="inner">❅</div>
        </div>
        <div className="snowflake">
          <div className="inner">❅</div>
        </div>
        <div className="snowflake">
          <div className="inner">❅</div>
        </div>
        <div className="snowflake">
          <div className="inner">❅</div>
        </div>
        <div className="snowflake">
          <div className="inner">❅</div>
        </div>
        <div className="snowflake">
          <div className="inner">❅</div>
        </div>
      </div>
    );
}

export default Snowflakes;
