import './ChristmasCard.css';

function elementsAsLines(elements) {
    const lines = [];

    for (const element of elements) {
        lines.push(<span>{element}</span>);
        lines.push(<br />);
    }

    if (lines.length > 0) {
        lines.pop();
    }

    return lines;
}

function elementsAsParagraphs(elements) {
    const rows = [];

    for (const element of elements) {
        rows.push(<p>{element}</p>)
    }

    return rows;
}

function ChristmasCard({ greeting }) {

    return (
        <div className="ChristmasCard">
            {elementsAsLines(greeting.salutation)}
            {elementsAsParagraphs(greeting.messages)}
            {elementsAsLines(greeting.signoff)}
        </div>
    );
}

export default ChristmasCard;