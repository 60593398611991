const endpoint = "https://reactmas-cards-api.arcus-adm01.workers.dev";

async function LoadCard(cardKey) {
    try {
        const response = await fetch(endpoint + "/GetCardData?" + new URLSearchParams({
            cardKey: cardKey
        }));

        if (!(response.ok && response.headers.get('Content-Type') == "application/json")) {
            return null;
        }

        const cardData = await response.json();

        console.log(cardData);
        return cardData;
    }
    catch (e) {
        return null;
    }
}

export default LoadCard;