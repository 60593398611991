import './App.css';
import ChristmasCard from './ChristmasCard';
import LoadCard from './LoadCard';
import React, { Component } from 'react';
import Snowflakes from './Snowflakes';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardData: null,
      loading: true,
    };
  }

  async componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    let cardData = await LoadCard(queryParams.get('cardKey'));

    if (cardData == null) {
      // Default in case of error or bad link
      cardData = {
        salutation: [ "Dear Visitor," ],
        messages: [ "Wishing you a Merry Christmas and a Happy New Year!" ],
        signoff: [ "All my best,", "James" ],
      };
    }

    this.setState({ cardData, loading: false });
  }

  render() {
    const { cardData, loading } = this.state;

    if (loading) {
      return (
        <div className="App">
          <header className="App-header">
            <h1>Merry Christmas!</h1>
          </header>
          <main className="App-main">
            <p>Loading...</p>
          </main>
          <Snowflakes />
        </div>
      );
    }

    return (
      <div className="App">
        <header className="App-header">
          <h1>Merry Christmas!</h1>
        </header>
        <main className="App-main">
          <ChristmasCard greeting={cardData}/>
        </main>
        <Snowflakes />
      </div>
    );
  }
}

export default App;
